.App {
  text-align: center;
  display: grid;
  justify-content: center;
  align-content: center;
  height: 100vh;
}

.child {
  padding: 15px;
  display: inline-block;
  border: 2px solid #E6D361;
  margin: 25px;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .space {
    justify-content: space-between;
  }
}

.title {
  padding-bottom: 1rem;
  text-align: left;
}

.logo-container {
  display: grid;
  justify-content: center;
  align-content: center;
}

@media (max-width: 768px) {
  .logo-container {
    margin-bottom: 10px;
  }
}

.image {
  padding: 20px;
}

.text-container {
  display: grid;
  justify-content: center;
  align-content: center;
  height: 100%;
}

@media (max-width: 768px) {
  .text-container {
    margin-top: 10px;
  }
}

.text {
  /* padding: 10px; */
  display: inline-block;
  text-align: left;
}

.links {
  text-align: center;
  padding-top: 15px;
  /* height: 3em; */
  display: inline-block;
}
.links a {
  padding: 5px;
  display: inline;
}

.links img{
  display: inline;
  width: 30px;
}